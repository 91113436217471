import { Grid } from "@mui/material";
import { BigStar } from "components/atoms/BigStar";
import { FC, Dispatch, SetStateAction, useState } from "react";

const MAX_STARS_DEFAULT = 5;

interface Props {
  score: number;
  setScore: Dispatch<SetStateAction<number>>;
  maxScore?: number;
}

export const StarsSelection: FC<Props> = ({
  score,
  setScore,
  maxScore = MAX_STARS_DEFAULT,
}) => {
  const [hoverScore, setHoverScore] = useState(score);
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  return (
    <Grid
      container
      sx={{
        width: "100%",
        gap: { md: 2, xs: 1.5 },
        display: "flex",
        justifyContent: "center",
      }}
      onMouseEnter={() => setOnMouseEnter(true)}
      onMouseLeave={() => setOnMouseEnter(false)}
    >
      {Array.from({ length: maxScore }).map((_, i) => {
        const selected = onMouseEnter ? i < hoverScore : i < score;

        return (
          <Grid item key={`star-big-${i}`}>
            <BigStar
              index={i}
              selected={selected}
              onHover={setHoverScore}
              onClick={setScore}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
