import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { COLORS, Therapist } from "@mapsy/shared";
import { CustomLink } from "./Link";
import {
  Tooltip,
  Typography,
  Grid,
  Popover,
  IconButton,
  useMediaQuery,
  Box,
} from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { theme } from "theme";

interface Props {
  therapist: Therapist;
}

export const ShareLinkSection: React.FC<Props> = ({ therapist }) => {
  const { _id, firstName, lastName, accountStatus } = therapist;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "popover-share-menu" : undefined;

  const url = useMemo(() => `${window.location.origin}/therapists/${_id}`, []);
  const [copiedText, setCopiedText] = useState(false);
  const message = useMemo(
    () =>
      `Hola, soy ${firstName} ${lastName}, terapeuta en Mapsy. Aquí podrás ver mis horarios disponibles. Elige alguno para agendar una cita conmigo.`,
    [firstName, lastName]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickWhatsApp = useCallback(() => {
    const encodedMessage = encodeURIComponent(`${message} \n${url}`);
    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  }, [url, message]);

  const handleClickFacebook = useCallback(() => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(message)}`,
      "_blank"
    );
  }, [url, message]);

  const handleClickTelegram = useCallback(() => {
    window.open(
      `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`,
      "_blank"
    );
  }, [url, firstName, lastName, message]);

  const handleClickLinkedIn = useCallback(() => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(message)}`,
      "_blank"
    );
  }, [url, message]);

  const handleClickCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedText(true);
    } catch (e) {
      alert("No fue posible copiar el link. Intenta otra opción");
    }
  }, [url]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (copiedText) {
      timeout = setTimeout(() => {
        setCopiedText(false);
      }, 2500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [copiedText]);

  const menuItems: { children: ReactNode; onClick: () => void }[] =
    useMemo(() => {
      const fontSize = isMobile ? "20px" : "26px";
      return [
        {
          children: <WhatsAppIcon sx={{ color: "#25D366", fontSize }} />,
          onClick: handleClickWhatsApp,
        },
        {
          children: <FacebookIcon sx={{ color: "#4267B2", fontSize }} />,
          onClick: handleClickFacebook,
        },
        {
          children: <TelegramIcon sx={{ color: "#24A1DE", fontSize }} />,
          onClick: handleClickTelegram,
        },
        {
          children: <LinkedInIcon sx={{ color: "#0a66c2", fontSize }} />,
          onClick: handleClickLinkedIn,
        },
        {
          children: <ContentCopyIcon color="info" sx={{ fontSize }} />,
          onClick: handleClickCopy,
        },
      ];
    }, [
      isMobile,
      handleClickWhatsApp,
      handleClickFacebook,
      handleClickTelegram,
      handleClickLinkedIn,
      handleClickCopy,
    ]);

  if (!accountStatus.registrationComplete) {
    return (
      <Tooltip
        disableFocusListener
        disableTouchListener
        title="Tu perfil público estará disponible una vez que completes tu información"
      >
        <Typography
          sx={{ textDecoration: "underline", color: COLORS.BLUE_1 }}
          variant="caption"
        >
          Perfil Público No Disponible
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Grid container sx={{ gap: 1 }}>
      <Grid item style={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Descubre cómo te ven los pacientes de mapsy"
          arrow
        >
          <Box>
            <CustomLink to={`/therapists/${_id}`} underline target="_blank">
              Ver mi perfil público
            </CustomLink>
          </Box>
        </Tooltip>
      </Grid>

      <Grid item>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Compartir perfil público"
          arrow
        >
          <IconButton
            aria-describedby={popoverId}
            onClick={handleClick}
            size={isMobile ? "small" : "medium"}
          >
            <Tooltip
              title="Copiado"
              PopperProps={{
                disablePortal: true,
              }}
              open={copiedText}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <IosShareIcon fontSize="inherit" sx={{ color: COLORS.BLUE_1 }} />
            </Tooltip>
          </IconButton>
        </Tooltip>

        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Compartir perfil público"
          arrow
        >
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              ".MuiPopover-paper": {
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                backgroundColor: "#f8fafb", //tinte del COLORS.WHITE sin llegar al blanco puro
                borderRadius: "8px",
                px: { md: 1, xs: 0.5 },
                py: { md: 0.5, xs: 0.25 },
                display: "flex",
              },
            }}
          >
            {menuItems.map(({ onClick, ...rest }) => (
              <IconButton
                {...rest}
                onClick={(e) => {
                  handleClose();
                  onClick();
                }}
                size={isMobile ? "small" : "medium"}
              />
            ))}
          </Popover>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
