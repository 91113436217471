import { useNavigate } from "react-router-dom";

import { Box, Container, List, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";

import therapist from "../../assets/img/therapist.webp";
import { CustomButton } from "../atoms/Button";
import { CheckIconItem } from "../atoms/CheckIconItem";
import { BannerGrid } from "../atoms/BannerGrid";
import { COLORS, StylesEnum } from "@mapsy/shared";
import { useCallback } from "react";

export const Find = () => {
  const nav = useNavigate();

  const handleClickAISearch = useCallback(() => {
    nav("/reason");
  }, []);

  return (
    <Container maxWidth="lg" component="section">
      {/* 2 rows */}
      <Grid2
        container
        direction={{ xs: "row", md: "column" }}
        sx={{
          height: { md: "100vh", xs: "auto" },
          justifyContent: "space-evenly",
        }}
      >
        {/* 2 columns */}
        <Grid2
          xs={12}
          container
          sx={{ minHeight: "373px", pt: { xs: 6, md: 0 } }}
        >
          {/* 3 rows */}
          <Grid2 xs={12} lg={6} sx={{ textAlign: "left" }}>
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="h4"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  mb: 2,
                  fontWeight: 500,
                }}
              >
                Encuentra a tu psicólogo ideal con Inteligencia Artificial
              </Typography>
              <List
                sx={{ padding: { xs: 0 }, margin: { xs: "auto", md: "0" } }}
              >
                <CheckIconItem text="Búsqueda eficiente y personalizada" />
                <CheckIconItem text="Psicólogos especializados" />
                <CheckIconItem text="Reservas en línea" />
              </List>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: { md: 2, xs: 1 },
                  marginTop: 3,
                }}
              >
                <CustomButton
                  customStyle={StylesEnum.primary}
                  onClick={handleClickAISearch}
                  sx={{
                    color: COLORS.PURE_WHITE,
                    alignSelf: { xs: "center", md: "start" },
                  }}
                >
                  Comenzar
                </CustomButton>
              </Box>
            </Box>
          </Grid2>

          <Grid2
            xs={12}
            lg={6}
            sx={{ justifyContent: "center", display: "flex" }}
          >
            {/* single */}
            <Box
              component="img"
              src={therapist}
              alt="mapsy-therapists"
              sx={{ maxWidth: { xs: "230px", md: "373px" } }}
            />
          </Grid2>
        </Grid2>
        {/* 3 columns */}
        <BannerGrid
          items={[
            { title: "+100", subtitle: "Psicólogos especializados" },
            { title: "+80", subtitle: "Áreas de atención" },
            { title: "100%", subtitle: "Terapias basadas en evidencia" },
          ]}
          itemBottomMargin={6}
          boxStyle={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        />
      </Grid2>
    </Container>
  );
};
