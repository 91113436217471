import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  selectReasonState,
  setAskForConsultingReason,
} from "../features/reason/reasonSice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { TherapistsList } from "../components/organisms/TherapistsList";
import { TherapistSearchLayout } from "../layouts/TherapistSearchLayout";
import { LoadingEnum } from "@mapsy/shared";
import { Box, Typography } from "@mui/material";
import { CustomLink } from "components/atoms/Link";
import { SELECTED_APPOINTMENT } from "components/molecules/TherapistCard";

export interface SuggestedPsychologistsPageProps {
  showAllTherapists?: boolean;
}
const SuggestedPsychologists: React.FC<SuggestedPsychologistsPageProps> = ({
  showAllTherapists,
}) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const { consultingActions, therapistsLoading, askForConsultingReason } =
    useAppSelector(selectReasonState);

  useEffect(() => {
    sessionStorage.removeItem(SELECTED_APPOINTMENT);
    if (!showAllTherapists) {
      return
    }
    dispatch(setAskForConsultingReason(!showAllTherapists));
  }, [showAllTherapists, dispatch]);

  useEffect(() => {
    const { predictedTopicId } = consultingActions;

    if (!predictedTopicId && !showAllTherapists) {
      nav("/reason");
    }
  }, [consultingActions, showAllTherapists]);

  return (
    <TherapistSearchLayout
      title="Psicólogos"
      loading={therapistsLoading === LoadingEnum.pending}
    >
      <TherapistsList showAllTherapists={showAllTherapists} />
    </TherapistSearchLayout>
  );
};

export default SuggestedPsychologists;
