import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, useMediaQuery } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { AccountStatus } from "@mapsy/shared";
import defaultUserPic from "assets/img/user-pic.webp";
import COLORS from "constants/colors";
import logger from "logger/logger";
import { useImageCache } from "providers/ImageCacheProvider";
import { theme } from "theme";

interface Props {
  _id: string;
  accountStatus: AccountStatus;
  overridenURL?: string;
  editButton?: {
    visible: boolean;
    onClick: () => void;
  };
}
export const ProfilePic: React.FC<Props> = ({
  _id,
  accountStatus,
  overridenURL,
  editButton,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const observerTarget = useRef(null);
  const [onViewport, setOnViewport] = useState(false);

  const editButtonSx = useMemo(() => {
    if (editButton?.visible && !isMobile) {
      return {
        "&:hover > img": {
          backgroundColor: "#B0BEC5",
          opacity: 0.1,
        },
        "&:hover > .edit-icon-container": {
          display: "flex",
          cursor: "pointer",
        },
      };
    }

    return {
      "&:hover > img": {
        //@TODO Usar este hover solo en la lista de los terapeutas y hacerlo clickable para ver el perfil público
        transform: "scale(1.1)",
      },
    };
  }, [editButton, isMobile]);

  const imageSrc = useMemo(() => {
    if (error) {
      return defaultUserPic;
    }

    if (overridenURL) {
      return overridenURL;
    }

    if (accountStatus.hasProfilePic) {
      return `/api/file/profile_pic_${_id}`;
    }

    return defaultUserPic;
  }, [overridenURL, accountStatus, _id, error]);

  const { imageBlob, loading: blobLoading } = useImageCache(
    imageSrc,
    onViewport
  );

  const handleImageLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const handleImageError = useCallback(() => {
    logger.error(`Error loading pic for ${_id}`);
    setLoading(false);
    setError(true);
  }, []);

  useEffect(() => {
    const currentTarget = observerTarget.current;
    if (!currentTarget) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setOnViewport(true);
        }
      },
      { threshold: 1 }
    );

    observer.observe(currentTarget);
    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget]);

  return (
    <Box
      ref={observerTarget}
      sx={{
        textAlign: "center",
        position: "relative",
        ...editButtonSx,
        "& > img": {
          xs: {
            maxWidth: "160px",
            maxHeight: "160px",
          },
          md: {
            maxWidth: "190px",
            maxHeight: "190px",
          },
        },
      }}
    >
      <img
        src={imageBlob}
        onLoad={handleImageLoad}
        onError={handleImageError}
        className={`profile-image ${loading || blobLoading ? "loading" : ""}`}
        alt={`Pic of ${_id}`}
      />
      {editButton?.visible && !isMobile && (
        <Box
          className="edit-icon-container"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "none",
            alignItems: "center",
            justifyContent: "center", // Optional, to add a semi-transparent background
            borderRadius: 10,
          }}
          onClick={(e) => editButton.onClick()}
        >
          <EditIcon
            className="edit-icon"
            sx={{
              color: COLORS.BLUE_1, // Adjust the color as needed
              fontSize: "2rem", // Adjust the size as needed
            }}
          />
        </Box>
      )}
      {editButton?.visible && isMobile && (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: COLORS.BLUE_1,
            top: { xs: "88%", sm: "85%" },
            left: { xs: "68%", sm: "83%" },
            width: "30px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Optional, to add a semi-transparent background
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={(e) => editButton.onClick()}
        >
          <EditIcon
            className="edit-icon"
            sx={{
              color: COLORS.WHITE, // Adjust the color as needed
              fontSize: "1rem", // Adjust the size as needed
            }}
          />
        </Box>
      )}
    </Box>
  );
};
