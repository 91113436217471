import { SocialMediaReferrer, TrafficSource } from "@mapsy/shared";
import { useEffect, useState } from "react";

export const useTrafficSource = () => {
  const [trafficSource, setTrafficSource] = useState<TrafficSource>();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("fbclid")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.FB,
        id: params.get("fbclid"),
        fromAd: true,
      });
      return;
    }

    if (params.has("gclid")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.GOOGLE,
        id: params.get("gclid"),
        fromAd: true,
      });
      return;
    }

    if (params.has("ttclid")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.TIKTOK,
        id: params.get("ttclid"),
        fromAd: true,
      });
      return;
    }

    const referrer = document.referrer;
    if (referrer.includes("instagram.com")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.INSTA,
        fromAd: false,
      });
      return;
    }

    if (referrer.includes("facebook.com")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.FB,
        fromAd: false,
      });
      return;
    }

    if (referrer.includes("google.com")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.GOOGLE,
        fromAd: false,
      });
      return;
    }

    if (referrer.includes("tiktok.com")) {
      setTrafficSource({
        referrer: SocialMediaReferrer.TIKTOK,
        fromAd: false,
      });
      return;
    }

    if (referrer) {
      setTrafficSource({
        referrer,
        fromAd: false,
      });
    }
  }, []);

  return { trafficSource };
};
