import {
  Box,
  CircularProgress,
  Container,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import COLORS from "../constants/colors";
import { SuggestedPsychologistsProps } from "interfaces";
import { useNavigate } from "react-router-dom";

export const TherapistSearchLayout = ({
  title,
  subtitle,
  loading,
  children,
}: SuggestedPsychologistsProps) => {
  const nav = useNavigate();
  const breadcrumbStyles: SxProps<Theme> = {
    color: "initial",
    cursor: "pointer",
    fontWeight: 600,
    width: "min-content",
    "&:hover": {
      textDecoration: "underline",
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        paddingY: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: "1rem",
          position: "relative",
          px: { md: 2, xs: 3 },
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="h4"
            sx={
              subtitle
                ? breadcrumbStyles
                : {
                    color: COLORS.BLUE_1,
                    fontWeight: 600,
                    width: "min-content",
                  }
            }
            onClick={() => {
              if (!subtitle) {
                return;
              }
              if (window.history.length > 2) { // only if user has navigated for more than the therapist view page
                nav(-1);
                return;
              }
              nav("/");
            }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="h4"
              style={{
                color: COLORS.BLUE_1,
                fontWeight: 600,
                width: "fit-content",
              }}
            >
              /{subtitle}
            </Typography>
          )}
        </Box>
        {children}
        {loading && <CircularProgress sx={{ margin: "auto" }} />}
      </Container>
    </Box>
  );
};
