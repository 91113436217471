import { Box } from "@mui/material";
import React from "react";
import { Stars } from "../Stars";
import { COLORS } from "@mapsy/shared";

interface Props {
  stars: 0 | 1 | 2 | 3 | 4 | 5;
  showTitle?: boolean;
}
export const TherapistStars: React.FC<Props> = ({ stars, showTitle }) => {
  return (
    <>
      {showTitle && (
        <Box component={"span"} sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}>
          Puntuación:{" "}
        </Box>
      )}
      {stars ? (
        <Box sx={{ width: 120 }}>
          <Stars stars={stars} />
        </Box>
      ) : (
        <>Sin valoraciones</>
      )}
    </>
  );
};
