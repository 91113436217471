import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  setAskForConsultingReason,
} from "../features/reason/reasonSice";
import { Typography } from "@mui/material";
import { TherapistSearchLayout } from "../layouts/TherapistSearchLayout";
import { useAxios } from "hooks/useAxios";
import { TherapistCard } from "components/molecules/TherapistCard";
import COLORS from "constants/colors";
import { capitalizeName } from "utils/capitalize";
import { Therapist, EndpointGenerator } from "@mapsy/shared";
import { useAppDispatch } from 'hooks';

const TherapistPage = () => {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { therapistId } = useParams();
  const [loading, setLoading] = useState(true);
  const [therapist, setTherapist] = useState<Therapist>();
  const [subtitle, setSubtitle] = useState("");

  const {
    isLoading: axiosLoading,
    getData,
    errorMsg,
  } = useAxios({
    loadingDefault: true,
  });

  useEffect(() => {
    // El state es seteado desde la lista de terapeutas
    // Si viene desde la lista de tereapeutas, se entiende 
    // que uso el buscador, entonces el consulting reason esta populado
    // const prevPage = location.state?.prev

    // TODO: considerar setear el consultingAction a algo como
    // "Visita subsecuente con especialista" o algo fijo
    // dispatch(setNewConsultingActions({ }))
    dispatch(setAskForConsultingReason(false));
  }, [location.state?.prev, dispatch])

  useEffect(() => {
    setLoading(axiosLoading);
  }, [axiosLoading]);

  useEffect(() => {
    if (!therapistId) {
      return;
    }

    const fetchTherapist = async () => {
      const t = await getData(EndpointGenerator.TherapistAPI.urlById(therapistId));
      setTherapist(t);
    };

    fetchTherapist();
  }, [therapistId, getData]);

  useEffect(() => {
    if (!therapist) {
      return;
    }

    const { firstName, middleName, lastName } = therapist;

    setLoading(false);
    setSubtitle(capitalizeName([firstName, middleName, lastName]));
  }, [therapist]);

  if (!therapist && !loading) {
    return (
      <TherapistSearchLayout title="Psicólogos" loading={loading} subtitle="">
        <Typography variant="body2">
          Este terapeuta no se encuentra disponible. Intenta buscando otro.
        </Typography>
        <Typography
          onClick={() => {
            if (location.state?.prev) {
              nav(location.state?.prev, { replace: true, state: null })
            } else {
              nav(-1)
            }
          }}
          sx={{
            color: COLORS.BLUE_1,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Volver
        </Typography>
      </TherapistSearchLayout>
    );
  }

  return (
    <TherapistSearchLayout
      title="Psicólogos"
      subtitle={subtitle}
      loading={loading}
    >
      {errorMsg && (
        <Typography variant="body2">No se pudo obtener el terapeuta</Typography>
      )}
      {therapist && <TherapistCard isSingleView therapist={therapist} />}
    </TherapistSearchLayout>
  );
};

export default TherapistPage;
