import { useState } from "react";
import { COLORS } from "@mapsy/shared";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import {
  FAQGenericItems,
  FAQPatientItems,
  FAQTherapistItems,
} from "constants/FAQItems";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FAQPage = () => {
  const [expanded, setExpanded] = useState<string | false>("panel-generic-0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          width: "100%",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          backgroundColor: COLORS.WHITE,
          textAlign: "center",
          p: { md: 2, xs: 1 },
          my: { md: 2, xs: 1 },
        }}
      >
        <Typography variant="h3" sx={{ color: COLORS.BLUE_1, fontWeight: 500 }}>
          Preguntas frecuentes
        </Typography>
      </Box>
      <Box>
        {FAQGenericItems.map(({ question, answer }, i) => {
          const id = `panel-generic-${i}`;
          return (
            <Accordion
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
              expanded={expanded === id}
              onChange={handleChange(id)}
              key={id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content"`}
                id={`${id}-header"`}
                sx={{
                  color: COLORS.BLUE_1,
                  fontWeight: 600,
                }}
              >
                <Typography variant="h5">{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Typography
          variant="h3"
          sx={{
            color: COLORS.BLUE_1,
            fontWeight: 500,
            textAlign: "center",
            my: 2.5,
          }}
        >
          ¿Eres paciente?
        </Typography>
        {FAQPatientItems.map(({ question, answer }, i) => {
          const id = `panel-patient-${i}`;
          return (
            <Accordion
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
              expanded={expanded === id}
              onChange={handleChange(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content"`}
                id={`${id}-header"`}
                sx={{
                  color: COLORS.BLUE_1,
                  fontWeight: 600,
                }}
              >
                <Typography variant="h5">{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Typography
          variant="h3"
          sx={{
            color: COLORS.BLUE_1,
            fontWeight: 500,
            textAlign: "center",
            my: 2.5,
          }}
        >
          ¿Eres terapeuta?
        </Typography>
        {FAQTherapistItems.map(({ question, answer }, i) => {
          const id = `panel-therapist-${i}`;
          return (
            <Accordion
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
              expanded={expanded === id}
              onChange={handleChange(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content"`}
                id={`${id}-header"`}
                sx={{
                  color: COLORS.BLUE_1,
                  fontWeight: 600,
                }}
              >
                <Typography variant="h5">{question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2">{answer}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Container>
  );
};
